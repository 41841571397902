<template>
  <!-- <div class="container"> -->
  <div class="fav-list">
    <div class="works-view" v-if="favList.length == 0">
      <div class="none">
        <img src="@/assets/images/nodata.png" />
        暂无
      </div>
    </div>
    <div
      :title="item.vote_code"
      @click="$router.push('/workdetail/' + item.vote_id)"
      v-for="item in favList"
      :key="item.vote_id"
    >
      <div class="works-view">
        <img class="work-cover" :src="item.image" mode="aspectFill" />
        <span class="works-title">{{ item.title }}</span>
        <div class="works-info">
          <div class="works-score">{{ item.total_score }} <span>赞</span></div>
          <div class="fav-pannel">
            <div>{{ item.member_score }}次</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { voteRecoredList } from "../network/API";
import emitter from "../utils/mitt";
import tools from "../utils/tools";

const favList = ref([]);
onMounted(() => {
  getList();
  tools.shareInit();
  emitter.on("refreshList", refreshList);
});
async function getList() {
  let list = await voteRecoredList();
  console.log(list);
  favList.value = list;
}
function refreshList() {
  getList();
}
</script>

<style scoped>
.fav-list {
  position: relative;
  z-index: 3;
  padding: 15px;
}

/* list */
.works-view {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  border-radius: 15px;
  padding: 15px;
}
/*  */
.work-cover {
  border-radius: 10px;
  height: 178px;
  object-fit: cover;
}
.works-title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 600;
}
.works-div text {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  font-weight: 700;
}
.works-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.works-score {
  font-size: 20px;
}
.works-score span {
  font-size: 15px;
  color: #999;
}
.work-fav {
  padding: 9px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 175px;
  height: 32px;
  border-radius: 16px;
  background: linear-gradient(110deg, #ffd993 -38%, #ff6d00 61%);
  color: white;
  justify-items: center;
  align-items: center;
  font-size: 15px;
}

.work-fav image {
  width: 16px;
  height: 15px;
  margin-right: 8px;
}
.fav-pannel {
  text-align: right;
  font-size: 15px;
  color: #333;
}

.fav-date {
  font-size: 12px;
  color: #9e9e9e;
}

/* .panel {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px 15px;
  top: 100px;
} */
</style>
